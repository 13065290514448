/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Navigation } from 'lucide-react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Modal } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TBrandDetails, TDiningArea, TDiningTable, TMerchantBranch, TMerchantDetails } from '../common/types';
import sharedApi from '../common/shared.api';
import { useIsLoading } from '../common/hooks';
import headerApi from '../header/header.api';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';
import { ThemeContext } from '../../contexts/theme.context';

const Landing = () => {
  const { setIsLoading } = useIsLoading();
  const { setOrderCreationStore, reset } = useOrderCreationStore();
  const navigate = useNavigate();
  const [sParams, setSearchParams] = useSearchParams();
  const tableId = sParams.get('t');
  const brandDetails: TBrandDetails | undefined =
    JSON.parse(localStorage.getItem('brandDetails') || 'null') || undefined;
  const merchantDetails: TMerchantDetails | undefined =
    JSON.parse(localStorage.getItem('merchantDetails') || 'null') || undefined;

  const { theme, setThemeObject } = useContext(ThemeContext);

  const [address, setAddress] = useState('');

  const fetchMenu = async (branch: TMerchantBranch) => {
    setIsLoading(true);
    const { data, status } = await headerApi.getMenu(branch.branch_id);
    if (status === 200) {
      sParams.delete('t');
      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('customerDetails');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandId');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('savedLocations');
      localStorage.removeItem('selectedPickupLocation');
      localStorage.removeItem('selectedDeliveryLocation');
      localStorage.removeItem('orderType'); // ? remove

      const updatedMenu = data.data?.map((category) => ({
        ...category,
        menu_items: category.menu_items?.map((item) => ({
          ...item,
          item_category_id: category.item_category_id,
        })),
      }));
      const updatedStore: any = reset();
      let diningArea: TDiningArea | null = null;
      setTimeout(async () => {
        if (tableId) {
          const { data: dinningAreasData, status: dinningAreasStatus } = await sharedApi.getFacilityDiningAreas(
            branch.facility.facility_id,
          );
          if (dinningAreasStatus === 200) {
            diningArea =
              dinningAreasData.data.find((area: TDiningArea) =>
                area.dining_table_list.find((table: TDiningTable) => table.dining_table_id === Number(tableId)),
              ) || null;
          }
        }
        setOrderCreationStore({
          ...updatedStore,
          orderTicket: {
            ...updatedStore.orderTicket,
            ...(diningArea
              ? {
                  dining_table_id: tableId,
                  dining_table_name: diningArea?.dining_table_list.find(
                    (table) => table.dining_table_id === Number(tableId),
                  )?.dining_table_name,
                  dining_area_id: diningArea?.dining_area_id,
                  dining_area_name: diningArea?.dining_area_name,
                }
              : {}),
            integration_details: {
              ...updatedStore.orderTicket.integration_details,
              facility_id: branch.facility.facility_id,
              branch_id: branch.branch_id,
              brand_id: branch.brand.brand_id,
              marketplace_id: branch.marketplace.marketplace_id,
              fulfillment_type: tableId ? 'dine-in' : 'restaurant-delivery',
              fulfillment_type_id: tableId ? 1 : 0,
            },
          },
          menu: updatedMenu,
          userConfig: undefined,
          availableFulfillmentTypes: branch.facility.fulfillment_types,
        });
        setSearchParams(sParams);
        window.location.href = '/menu';
      }, 100);
    } else {
      Modal.info({
        title: 'Location not supported',
        content: (
          <div>
            <p>We are not available on the selected location.</p>
          </div>
        ),
        className: 'rounded-md',
      });
    }
    setIsLoading(false);
  };

  const fetchBrandDetails = async (branch: TMerchantBranch) => {
    setIsLoading(true);
    const { data, status } = await headerApi.getBrandDetailsById(branch.brand.brand_id);
    if (status === 200) {
      const logoImage = data.data.brand_image_list.find((image) => image.image_type === 'logo')?.image_url || null;
      const coverImage = data.data.brand_image_list.find((image) => image.image_type === 'cover')?.image_url || null;
      const bannerImage = data.data.brand_image_list.find((image) => image.image_type === 'banner')?.image_url || null;

      localStorage.setItem(
        'brandDetails',
        JSON.stringify({
          ...data.data,
          logo_image: logoImage,
          cover_image: coverImage,
          banner_image: bannerImage,
        }),
      );

      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('diningAreas');
      localStorage.removeItem('customerDetails');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('merchantCode');
      localStorage.removeItem('savedLocations');
      localStorage.removeItem('selectedPickupLocation');
      localStorage.removeItem('selectedDeliveryLocation'); // ? remove
      localStorage.removeItem('orderType'); // ? remove
      reset();
      // TODO: get dining area from table id
      // const diningArea: TDiningArea | null = null;
      setTimeout(async () => {
        // if (tableId) {
        //   const { data: dinningAreasData, status: dinningAreasStatus } = await sharedApi.getFacilityDiningAreas(
        //     data.data.facility.facility_id,
        //   );
        //   if (dinningAreasStatus === 200) {
        //     diningArea =
        //       dinningAreasData.data.find((area: TDiningArea) =>
        //         area.dining_table_list.find((table: TDiningTable) => table.dining_table_id === Number(tableId)),
        //       ) || null;
        //   }
        // }
        if (data.data.brand_facility_web_element_list) {
          const logoElement = data.data.brand_facility_web_element_list.header?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'brand_logo',
          )?.brand_facility_web_element_image_map_list;
          const headerElements = {
            backgroundColor: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'background_color',
            )?.web_element_value,
            textColor: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color',
            )?.web_element_value,
            textColorSecondary: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color_secondary',
            )?.web_element_value,
            logoUrl: logoElement ? logoElement[0]?.brand_facility_web_element_image_map_url : undefined,
          };
          const generalElements = {
            backgroundColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'background_color',
            )?.web_element_value,
            buttonColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'button_color',
            )?.web_element_value,
          };
          setThemeObject({ ...theme, header: headerElements, general: generalElements });
          fetchMenu(branch);
        }
        // window.location.reload();
      }, 100);
      setIsLoading(false);
    } else navigate('/404', { state: { message: 'Please scan a valid QR code to continue' } });
  };

  const handleSelect = async (value: string) => {
    setIsLoading(true);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);

    const { data } = await sharedApi.getMerchantBranches(
      {
        latitude: String(latLng.lat),
        longitude: String(latLng.lng),
        brand_id: merchantDetails ? undefined : brandDetails?.brand_id,
      },
      merchantDetails?.merchant_id || brandDetails?.merchant.merchant_id || 0,
    );

    const nearestBranch =
      data.data
        .filter((branch) => branch.facility.fulfillment_types.some((type) => [3, 4].includes(type.fulfillment_type_id)))
        .sort((a, b) => Number(a.facility.cand_distance_km) - Number(b.facility.cand_distance_km))
        .find((branch) => Number(branch.facility.delivery_range_km) - Number(branch.facility.cand_distance_km) > 0) ||
      null;

    localStorage.setItem('selectedLocation', results[0]?.address_components[0]?.long_name || value);

    const uniqueBrands = data.data.reduce((acc: TMerchantBranch[], branch: TMerchantBranch) => {
      if (
        !acc.some((b) => b.brand.brand_id === branch.brand.brand_id) &&
        Number(branch.facility.delivery_range_km) - Number(branch.facility.cand_distance_km) > 0
      ) {
        return [
          ...acc,
          {
            ...branch,
            brand: {
              ...branch.brand,
              logo_image: branch.brand.brand_image_list.find((image) => image.image_type === 'logo')?.image_url || null,
              cover_image:
                branch.brand.brand_image_list.find((image) => image.image_type === 'cover')?.image_url || null,
              banner_image:
                branch.brand.brand_image_list.find((image) => image.image_type === 'banner')?.image_url || null,
            },
          },
        ];
      }
      return acc;
    }, []);

    // localStorage.setItem('menuType', 'merchant');
    if (merchantDetails && uniqueBrands.length > 0) {
      localStorage.setItem('merchantBranches', JSON.stringify(uniqueBrands));
      setIsLoading(false);
      if (uniqueBrands.length === 1) fetchBrandDetails(uniqueBrands[0]);
      else navigate('/brands');
    } else if (!nearestBranch || uniqueBrands.length === 0) {
      Modal.info({
        title: 'Location not supported',
        content: (
          <div>
            <p>We are not available on the selected location.</p>
          </div>
        ),
        className: 'rounded-md',
      });
      setIsLoading(false);
    } else {
      fetchMenu(nearestBranch);
    }
  };

  return (
    <div className="w-full h-fill flex flex-col flex-grow relative">
      {brandDetails || merchantDetails ? (
        <img
          src={
            merchantDetails?.cover_image || brandDetails?.cover_image || `${process.env.PUBLIC_URL}/assets/brand.png`
          }
          alt={merchantDetails?.merchant_name || brandDetails?.brand_name || 'iBlink'}
          className="absolute h-full overflow-hidden w-full object-cover"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/404.png`;
          }}
        />
      ) : null}
      <div className="relative h-fill flex items-center justify-center flex-col -mt-32">
        <img
          src={`${merchantDetails?.logo_image || brandDetails?.logo_image || `/assets/brand.png`}`}
          alt="logo"
          className="h-32 md:h-64 z-10 px-4 md:px-0"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/brand.png`;
          }}
        />
        {brandDetails?.iblinkmarketplace_enabled_p === 1 || merchantDetails ? (
          <div
            className="py-2 rounded-md bg-white shadow-md border z-10 w-[95%] md:w-1/2 text-base mx-2 md:mx-0 mt-4"
            style={{
              backgroundColor: theme?.general?.cardBackgroundColor || '',
              color: theme?.general?.textColor || '',
            }}
          >
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: 'ae' },
              }}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="relative px-2">
                  <div className="flex gap-1 items-center justify-center">
                    <Navigation className="h-6 w-6" />
                    <input
                      className="border-0 bg-white w-full my-1 focus:outline-none focus:ring-0 px-0 py-1"
                      {...getInputProps({ placeholder: 'Search address' })}
                      style={{
                        backgroundColor: theme?.general?.cardBackgroundColor || '',
                        color: theme?.general?.textColor || '',
                      }}
                    />
                    {address ? (
                      <button type="button" onClick={() => setAddress('')}>
                        <XCircleIcon className="h-6 w-6" />
                      </button>
                    ) : null}
                  </div>

                  <div
                    className="absolute top-0 left-0 flex flex-col gap-2 bg-white mt-12 rounded-b-md w-full"
                    style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
                  >
                    {loading ? <div>...loading</div> : null}

                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? theme.general?.buttonColor || '#41bfef'
                          : theme?.general?.cardBackgroundColor || '#fff',
                      };

                      // eslint-disable-next-line react/jsx-key
                      return (
                        <div
                          className="px-1 py-1 rounded-sm"
                          {...getSuggestionItemProps(suggestion, { style })}
                          key={suggestion.id}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        ) : null}
      </div>
      <div className="absolute bottom-0 right-0 flex justify-end text-sm text-white z-10 w-[95%] md:w-1/2">
        Powered by <img src="/ibp_logo_main.png" alt="iBlink" className="h-6 z-10" />
      </div>
    </div>
  );
};

export default Landing;
