/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { Steps } from 'antd';
import { useQuery } from 'react-query';
import { Bike, CheckCircle, ChefHat, Home, PackageCheck, RouteOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import orderSummaryApi from './order-summary.api';
import { ThemeContext } from '../../../contexts/theme.context';
import { TBrandDetails, TCustomerOrder } from '../../common/types';
import { OrderProductsList } from '../components';
import utils from '../../common/services/utils.service';

const OrderSummary = () => {
  const { theme } = useContext(ThemeContext);
  const customerOrderId = localStorage.getItem('customerOrderId') || '';
  const [customerOrder, setCustomerOrder] = useState<TCustomerOrder>();
  const [currentStep, setCurrentStep] = useState(1);
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');
  const navigate = useNavigate();
  const orderType = localStorage.getItem('orderType') || 'delivery';
  const paymentPointUrl = localStorage.getItem('paymentPointUrl') || '';

  const [stepItems, setStepItems] = useState([
    {
      title: 'Created',
      icon: <CheckCircle className="w-6 h-6 text-green-500" />,
    },
    {
      title: 'In Progress',
      icon: <ChefHat className="w-6 h-6" />,
    },
    {
      title: 'Dispatched',
      icon: <Bike className="w-6 h-6" />,
    },
    {
      title: 'Delivered',
      icon: <PackageCheck className="w-6 h-6" />,
    },
    {
      title: 'Delivered',
      icon: <RouteOff className="w-6 h-6 text-red-500" />,
    },
  ]);

  const customerOrderDetailQuery = useQuery(
    'customerOrderDetailQuery',
    () => orderSummaryApi.getCustomerOrderDetails(Number(customerOrderId)),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setCustomerOrder(data.data);
          const step = ['accepted', 'in-progress', 'dispatched', 'delivered', 'closed', 'canceled'].indexOf(
            data.data.order_status,
          );
          setCurrentStep(step === -1 ? 0 : step);
          setStepItems([
            {
              title: 'Order Received',
              icon: <CheckCircle className="w-6 h-6 text-green-500" />,
            },
            {
              title: 'In Kitchen',
              icon: (
                <ChefHat
                  className={utils.cn('w-6 h-6', {
                    'text-green-500': !['accepted', 'in-progress', 'cancelled', 'created'].includes(
                      data.data.order_status,
                    ),
                  })}
                />
              ),
            },
            {
              title: 'Out For Delivery',
              icon: (
                <Bike
                  className={utils.cn('w-6 h-6', {
                    'text-green-500': !['accepted', 'in-progress', 'dispatched', 'cancelled', 'created'].includes(
                      data.data.order_status,
                    ),
                  })}
                />
              ),
            },
            {
              title: 'Delivered',
              icon: (
                <PackageCheck
                  className={utils.cn('w-6 h-6', {
                    'text-green-500': ![
                      'accepted',
                      'in-progress',
                      'dispatched',
                      'delivered',
                      'cancelled',
                      'created',
                    ].includes(data.data.order_status),
                  })}
                />
              ),
            },
            ...(data.data.order_status === 'cancelled'
              ? [
                  {
                    title: 'Cancelled',
                    icon: <RouteOff className="w-6 h-6 text-red-500" />,
                  },
                ]
              : []),
          ]);
        }
      },
    },
  );

  useEffect(() => {
    if (customerOrderId) {
      customerOrderDetailQuery.refetch();
      setInterval(() => {
        customerOrderDetailQuery.refetch();
      }, 5000);
    } else navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOrderId]);

  return (
    <main
      className="relative lg:min-h-[93vh]"
      style={{ backgroundColor: theme?.general?.backgroundColor || '', color: theme.general?.textColor || '' }}
    >
      <div className="h-80 overflow-hidden lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12 lg:fixed">
        <img
          src={brandDetails?.cover_image || `${process.env.PUBLIC_URL}/assets/404.png`}
          alt={customerOrder?.brand_name}
          className="h-full w-full object-cover object-center"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/404.png`;
          }}
        />
      </div>

      <div className="">
        <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 sm:py-16 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-12 xl:gap-x-24">
          <div className="lg:col-start-2">
            <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">Thanks for ordering</p>
            <p className="text-base text-gray-400 mt-2" style={{ color: theme?.general?.descriptionTextColor || '' }}>
              We appreciate your order, we’re currently preparing it. So hang tight and we’ll send you your order very
              soon!
            </p>
            <dl className="mt-8 text-sm font-medium">
              <dt className="">Tracking number</dt>
              <dd className="mt-2 text-indigo-600" style={{ color: theme?.general?.buttonColor || '' }}>
                {customerOrder?.external_order_reference_nr}
              </dd>
            </dl>
            <div className="flex flex-col justify-center w-full m-1 shadow-md rounded-md border px-2 py-4 space-y-4">
              {stepItems.map((step, index) => (
                <div key={index} className="flex items-center gap-2" style={{ color: theme?.general?.textColor || '' }}>
                  {step.icon}
                  <div className="text-sm">{step.title}</div>
                </div>
              ))}
            </div>

            <h1 className="text-xl font-bold my-4">Items</h1>
            {customerOrder?.item_list && (
              <OrderProductsList
                products={customerOrder?.item_list}
                offers={customerOrder?.offer_list}
                paymentDetail={undefined}
              />
            )}

            <dl className="space-y-2 border-t mt-2 border-gray-200 pt-6 text-sm font-medium">
              <div className="flex justify-between">
                <dt>Subtotal</dt>
                <dd className="">
                  {utils.formatCurrency(
                    Number(customerOrder?.payable_amount) - Number(customerOrder?.delivery_amount),
                    customerOrder?.payable_currency_name,
                  )}
                </dd>
              </div>

              {customerOrder?.delivery_amount ? (
                <div className="flex justify-between">
                  <dt>Delivery</dt>
                  <dd className="">
                    {utils.formatCurrency(Number(customerOrder?.delivery_amount), customerOrder?.payable_currency_name)}
                  </dd>
                </div>
              ) : null}

              <div className="flex justify-between">
                <dt>Taxes</dt>
                <dd className="">
                  {utils.formatCurrency(Number(customerOrder?.tax_amount), customerOrder?.payable_currency_name)}
                </dd>
              </div>

              <div className="flex items-center justify-between border-t border-gray-200 pt-6 ">
                <dt className="text-base">Total</dt>
                <dd className="text-base">
                  {utils.formatCurrency(Number(customerOrder?.payable_amount), customerOrder?.payable_currency_name)}
                </dd>
              </div>
            </dl>
            <div className="flex justify-center">
              <button
                type="button"
                className="px-2 py-2 m-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex gap-2 justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
                style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                onClick={() => {
                  if (orderType !== 'dine-in') localStorage.removeItem('customerOrderId');
                  window.location.href = orderType === 'dine-in' && paymentPointUrl ? paymentPointUrl : `/menu`;
                }}
              >
                <Home className="w-6 h-6" />
                {orderType === 'dine-in' ? 'Back' : 'Order Again'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OrderSummary;
