/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Tabs } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import './product-detail.css';
import { isMobile } from 'react-device-detect';
import { ChevronLeft } from 'lucide-react';
import { Bounce, toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ImagesCarousel, Modifiers, Offer } from './components';
import productDetailApi from './product-detail.api';
import { MenuItem, ModifierSection, OfferMenuItem } from './product-detail.type';
import { ThemeContext } from '../../contexts/theme.context';
import utils, { hexToRgba } from '../common/services/utils.service';
import { TMenuItem, TModifierSection, TOrderLineItem } from '../common/types';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';
import { useIsLoading, useRecommendationModal } from '../common/hooks';
import sharedApi from '../common/shared.api';

const ProductDetail: React.FC = () => {
  const { setIsLoading } = useIsLoading();
  const { theme } = useContext(ThemeContext);
  const { setRecommendationsStore } = useRecommendationModal();
  const { orderCreationStore, updateLineItemModifiers, addLineItemToTicket } = useOrderCreationStore();
  const { orderTicket, menu } = orderCreationStore;
  const { id: menuItemId } = useParams();
  const [itemNotes, setItemNotes] = useState<string>('');
  const [menuItem, setMenuItem] = useState<TMenuItem>();
  const [offerMenuItem] = useState<OfferMenuItem>();
  const [modifierSections, setModifierSections] = useState<TModifierSection[]>([]);
  const [selectedOfferMenuItemIds, setSelectedOfferMenuItemIds] = useState<number[]>([]);
  const [offerSelectedMenuItems, setOfferSelectedMenuItems] = useState<MenuItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const recommended = searchParams.get('recommended');

  const [requiredModifierSelected, setRequiredModifierSelected] = useState<boolean>(false);
  const [requiredOfferSelected, setRequiredOfferSelected] = useState<boolean>(false);
  const [requiredOfferModifierSelected, setRequiredOfferModifierSelected] = useState<boolean>(false);
  const navigate = useNavigate();

  const [basket, setBasket] = useState<number>(1);
  const decrementCount = () => {
    const updatedCount = basket - 1;
    setBasket(updatedCount);
  };
  const incrementCount = () => {
    const updatedCount = basket + 1;
    setBasket(updatedCount);
  };

  const updateBrandAndAddToBasket = (addToBasketFunction: () => void) => {
    addToBasketFunction();
  };

  const showNotification = () => {
    toast.info(
      <div>
        <img
          src={
            menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
              ? menuItem?.menu_item_images[0]?.menu_item_image_url
              : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
          }
          alt="product"
          className="h-16 w-16 object-cover float-left rounded-lg"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`;
          }}
        />
        <div className="ml-20 capitalize">
          <b>{menuItem?.menu_item_name_en}</b>
        </div>
        <div className="ml-20">(X{basket}) Added to basket</div>
      </div>,
    );
  };

  const checkMenuItemRecommendation = async (item: TMenuItem) => {
    const { data, status } = await sharedApi.getMenuItemRecommendationByItemIds([item.item_id]);

    if (status === 200 && data.status === 'successful' && data.data.length > 0) {
      const fulfilmentTypeMenu = menu.map((category) => ({
        ...category,
        menu_items: category.menu_items
          .filter(
            (i) =>
              i.item_price_list.length === 0 ||
              item.item_price_list.find(
                (price) =>
                  price.fulfillment_type_id === orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
              ),
          )
          .map((i) => ({ ...i, item_category_id: category.item_category_id })),
      }));

      const selectedMenuCategory = fulfilmentTypeMenu
        .flatMap((category) => category.menu_items)
        .sort((a, b) => a.menu_item_name_en.localeCompare(b.menu_item_name_en))
        .sort((a, b) => Number(a.excluded_p) - Number(b.excluded_p));

      const orderTicketItems = orderTicket.item_list.map((i) => i.item_id);

      const recommendedItem = selectedMenuCategory.find(
        (i) =>
          data.data[0].customer_recommendation_p &&
          i.item_id === data.data[0].recommended_item_id &&
          !orderTicketItems.includes(i.item_id),
      );
      if (recommendedItem && !orderTicket.item_list.find((i) => i.item_id === data.data[0].recommended_item_id))
        toast.info('Live Chef Recommendation!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          style: {
            borderRadius: '15px',
            marginTop: isMobile ? '100px' : '50px',
            marginLeft: isMobile ? '10px' : 'auto',
            marginRight: isMobile ? '10px' : 'auto',
            backgroundColor: theme?.general?.buttonColor ? hexToRgba(theme?.general?.buttonColor, 1) : '',
            color: 'white',
          },
          progressStyle: {
            height: '10px',
            backgroundColor: theme?.general?.buttonColor || 'black',
            filter: 'invert(0.4)',
          },
          icon: <InformationCircleIcon className="h-8 w-8 text-white" style={{ color: 'white' }} />,
          transition: Bounce,
          onClick: () => {
            setRecommendationsStore({
              open: true,
              recommendations: data.data[0] ? [data.data[0]] : [],
              recommendedItemIds: [recommendedItem.item_id],
            });
          },
        });
    }

    navigate(`/menu`);
  };

  const onConfirmModifiers = async () => {
    if (!menuItem) return;
    setIsLoading(true);
    const updatedModifierSections = modifierSections
      ?.map((section) => ({
        external_modifier_section_id: section.modifier_section_external_id,
        display_section_name_en: section.modifier_section_name_en,
        display_section_name_ar: section.modifier_section_name_ar,
        order_line_item_modifier_section_id_list: section.order_line_item_modifier_section_id_list || [],
        modifier_choice_list: section.modifier_choices
          .filter((choice) => choice.choiceCount)
          .map((choice) => ({
            external_modifier_choice_id: choice.modifier_choice_external_id,
            order_line_item_modifier_choice_id_list: choice.order_line_item_modifier_choice_id_list || [],
            display_choice_name_en: choice.modifier_choice_name_en,
            display_choice_name_ar: choice.modifier_choice_name_ar,
            price: choice.one_unit_price,
            quantity: choice.choiceCount || 0,
          })),
      }))
      .filter((section) => section.modifier_choice_list.length > 0);
    const orderLineItem: TOrderLineItem = {
      id: menuItem.id || uuidv4(),
      external_item_id: menuItem.menu_item_external_id,
      item_id: menuItem.item_id,
      item_category_id: menuItem.item_category_id || 0,
      display_name_en: menuItem.menu_item_name_en,
      display_name_ar: menuItem.menu_item_name_ar,
      item_price: menuItem.menu_item_price,
      menu_item_image: menuItem?.menu_item_images.length > 0 ? menuItem.menu_item_images[0].menu_item_image_url : '',
      price: menuItem.menu_item_price,
      order_line_item_notes: itemNotes || undefined,
      item_price_list: menuItem.item_price_list || [],
      quantity: basket,
      modifier_section_list: updatedModifierSections || [],
      modifierSubtotal: 0,
      canHaveModifiers: true,
      currency_alpha_3: menuItem.currency_alpha_3,
      menu_item_recommendation_id: recommended ? Number(recommended) : undefined,
    };
    if (menuItem.id) updateLineItemModifiers(orderCreationStore, orderLineItem);
    else {
      const itemAdded = await addLineItemToTicket(orderLineItem);
      if (itemAdded) {
        // setOrderCreationStore({ ...orderCreationStore, selectedEditMenuItem: undefined });
        // checkMenuItemRecommendation(menuItem); // ! TODO: check if this is needed
        showNotification();
        navigate(`/menu`);
      } else {
        notification.error({
          message: 'Something went wrong',
          description: `Unable to add ${menuItem.menu_item_name_en} to the basket, please try again later`,
          placement: isMobile ? 'bottom' : 'topRight',
          duration: 1.5,
        });
      }
    }
    setIsLoading(false);
  };

  // const addToBasketPressed = async () => {
  //   setIsLoading(true);
  //   const selectedModifierSections: LineItemModifierSection[] = modifierSections
  //     .filter((section) => (section.totalChoiceCount || 0) > 0)
  //     .map((section) => ({
  //       modifier_section_id: section.modifier_section_id,
  //       section_name: section.modifier_section_display_name,
  //       modifier_choice_list: section.modifier_choices
  //         .filter((mc) => (mc.choice.choiceCount || 0) > 0)
  //         .map((mc) => ({
  //           modifier_choice_id: mc.choice.modifier_choice_id,
  //           choice_count: mc.choice.choiceCount || 0,
  //           modifier_name: mc.choice.display_choice_name,
  //           price: Number(mc.modifier_choice_price_list[0]?.one_unit_price || 0),
  //         })),
  //     }));

  //   const lineItem: OrderLineItem = {
  //     order_line_item: {
  //       menu_item_name: menuItem?.menu_item.menu_item_name_en || '',
  //       menu_item_id: Number(menuItemId),
  //       quantity: basket,
  //       order_line_item_notes: null,
  //       brand_menu_category_map_id: Number(menuItem?.menu_item.brand_menu_category_map_id),
  //       menu_item_price: menuItem?.price[0]?.default_price || 0,
  //     },
  //     line_item_modifier_list: selectedModifierSections,
  //   };
  //   if (await basketService.addToBasket(lineItem)) {
  //     showNotification();
  //     navigate(`/`);
  //   } else {
  //     notification.error({
  //       message: 'Something went wrong',
  //       description: 'Unable to add item to the basket',
  //     });
  //     setIsLoading(false);
  //   }
  // };

  // const addOfferToBasket = async () => {
  //   console.log('offerSelectedMenuItems', offerSelectedMenuItems);
  //   setIsLoading(true);
  //   const lineItems = offerSelectedMenuItems.map((item) => ({
  //     menu_item_id: item.menu_item.menu_item_id,
  //     menu_item_name: item.menu_item.menu_item_name_en,
  //     quantity: 1,
  //     order_line_item_notes: '',
  //     brand_menu_category_map_id: item.menu_item.brand_menu_category_map_id,
  //     menu_item_price: item.price[0]?.default_price,
  //     menu_line_item_modifier_list: item.modifierSections
  //       ? item.modifierSections
  //           .filter((choice) => (choice.totalChoiceCount || 0) > 0)
  //           .map((section) => ({
  //             modifier_section_id: section.modifier_section_id,
  //             section_name: section.modifier_section_display_name,
  //             modifier_choice_list: section.modifier_choices
  //               .filter((choice) => (choice.choice.choiceCount || 0) > 0)
  //               .map((choice) => ({
  //                 modifier_choice_id: choice.choice.modifier_choice_id,
  //                 choice_count: choice.choice.choiceCount,
  //                 modifier_name: choice.choice.display_choice_name,
  //                 price: Number(choice.modifier_choice_price_list[0]?.one_unit_price || 0),
  //               })),
  //           }))
  //       : [],
  //   }));

  //   const combinedLineItems = lineItems.reduce((acc: any[], cur: any) => {
  //     const existingItem = acc.find(
  //       (item: any) => JSON.stringify({ ...item, quantity: 0 }) === JSON.stringify({ ...cur, quantity: 0 }),
  //     );
  //     if (existingItem) {
  //       // eslint-disable-next-line no-multi-assign
  //       existingItem.quantity = existingItem.quantity += 1;
  //       return acc;
  //     }
  //     return [...acc, cur];
  //   }, []);

  //   const offerItem: OrderOfferItem = {
  //     offer_menu_item_id: offerMenuItem?.offer_menu_item_id || 0,
  //     offer_menu_item_name: offerMenuItem?.offer_menu_item_name || '',
  //     offer_id: offerMenuItem?.offer_id || 0,
  //     offer_type: offerMenuItem?.offer_type || '',
  //     offer_price: offerMenuItem?.offer_price || 0,
  //     quantity: basket,
  //     offer_section_and_choices_list:
  //       (offerMenuItem?.offer_section_and_choices_list.map((section) => ({
  //         offer_section_id: section.offer_section_id,
  //         offer_section_display_name_en: section.offer_section_display_name_en,
  //         offer_section_line_items_list: section.offer_section_line_items_list
  //           .filter((lineItem) => selectedOfferMenuItemIds.includes(lineItem.menu_item_id))
  //           .flatMap((lineItem) => {
  //             const selectedLineItem = combinedLineItems.filter((item) => item.menu_item_id === lineItem.menu_item_id);
  //             if (selectedLineItem.length > 0) {
  //               return selectedLineItem.map((selectedItem: any) => ({
  //                 offer_choice_id: lineItem.offer_choice_id,
  //                 ...selectedItem,
  //               }));
  //             }
  //             return {
  //               offer_choice_id: 0,
  //               menu_item_id: 0,
  //               menu_item_name: '',
  //               quantity: 1,
  //               order_line_item_notes: '',
  //               brand_menu_category_map_id: 0,
  //               menu_line_item_modifier_list: [],
  //             };
  //           }),
  //       })) as any) || [],
  //   };
  //   if (await basketService.addOfferToBasket(offerItem)) {
  //     showNotification();
  //     navigate(`/`);
  //   } else {
  //     notification.error({
  //       message: 'Something went wrong',
  //       description: 'Unable to add offer to the basket',
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const confirmOffer = () => {
    setIsLoading(true);
    const uniqueMenuItemIds = Array.from(new Set(selectedOfferMenuItemIds));
    uniqueMenuItemIds.forEach(async (itemId) => {
      const { data: menuItemData, status: menuItemStatus } = await productDetailApi.getMenuItem(Number(itemId));
      if (menuItemData && menuItemStatus === 200) {
        const { data: modifierData, status: modifierStatus } = await productDetailApi.getMenuItemModifierSections(
          Number(itemId),
        );

        if (modifierData && modifierStatus === 200) {
          menuItemData.modifierSections = (modifierData.modifier_list as ModifierSection[]).map((modifier) => ({
            ...modifier,
            totalChoiceCount: modifier.modifier_choices
              .map((mc) => Number(mc.choice.default_selection_p ? 1 : 0))
              .reduce((a, b) => a + b, 0),
            modifier_choices: modifier.modifier_choices.map((modifierChoice) => ({
              ...modifierChoice,
              choice: { ...modifierChoice.choice, choiceCount: modifierChoice.choice.default_selection_p ? 1 : 0 },
            })),
          }));
        }
        const offerMenuItems: MenuItem[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < selectedOfferMenuItemIds.filter((id) => itemId === id).length; i++) {
          offerMenuItems.push(JSON.parse(JSON.stringify({ ...menuItemData, iteration: i + 1 })));
        }
        setOfferSelectedMenuItems((prev) => [...prev, ...offerMenuItems]);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!menuItem && menuItemId) {
      window.scrollTo(0, 0);
      const selectedMenuItem = menu
        .flatMap((category) => category.menu_items)
        .find((item) => item.item_id === Number(menuItemId));
      if (selectedMenuItem) setMenuItem(selectedMenuItem);
    }
  }, [menuItemId]);

  useEffect(() => {
    if (offerSelectedMenuItems.length > 0) {
      let modifiersRequired = false;
      offerSelectedMenuItems.forEach((item) => {
        if (item.modifierSections && modifiersRequired === false) {
          modifiersRequired = !!item.modifierSections.find(
            (modifierSection) =>
              (modifierSection.totalChoiceCount || 0) <
              Number(modifierSection.min_choice_count || modifierSection.equal_to_choice_count),
          );
        }
      });

      if (modifiersRequired) setRequiredOfferModifierSelected(true);
      else setRequiredOfferModifierSelected(false);
    }
  }, [offerSelectedMenuItems]);

  return (
    <div className="relative bg-gray-100 pb-40" style={{ backgroundColor: theme?.general?.backgroundColor || '' }}>
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="fixed mt-1 left-1 bg-colorPrimary text-textPrimary px-2 pr-3 flex justify-center items-center py-1.5 rounded-full font-semibold disabled:opacity-50 z-50"
        style={{ backgroundColor: theme?.general?.buttonColor || '' }}
      >
        <ChevronLeft className="h-5 w-5 inline" aria-hidden="true" />
        Back
      </button>
      <div
        className="grid grid-cols-1 md:grid-cols-2 bg-white pb-4 rounded-b-2xl"
        style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
      >
        <div className="m-4 mt-0 md:mt-4 flex items-center justify-center">
          <ImagesCarousel
            imageData={
              menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
                ? menuItem?.menu_item_images.map((image) => image.menu_item_image_url)
                : [`${process.env.PUBLIC_URL}/assets/products/empty_menu.png`]
            }
          />
        </div>
        <div className="mt:2 md:mt-8 mx-8">
          <div className="grid grid-cols-3 md:grid-cols-1">
            <h1 className="text-2xl font-bold col-span-2 capitalize">{menuItem?.menu_item_name_en}</h1>
            {/* TODO: add Brand <h1 className="text-lg font-bold col-span-2 capitalize">{menuItem?.brand_name}</h1> */}
            {menuItem?.menu_item_price !== -1 && (
              <h3 className="font-semibold text-lg mt-2 text-right md:text-left col-span-1">
                {utils.formatCurrency(Number(menuItem?.menu_item_price), menuItem?.currency_alpha_3)}
              </h3>
            )}
          </div>
          <p className="text-gray-500 mt-2" style={{ color: theme.general?.descriptionTextColor || '' }}>
            {menuItem?.menu_item_description_en}
          </p>
          <hr className="mt-4 mb-2 border-gray-400" />
          {/* <p className="text-gray-500">Kcal: 547.87, Fat: 24.74, Carb: 5.18, Pro: 24.89</p> */}
          {/* {menuItem?.allergen && menuItem?.allergen.length > 0 && (
            <p className="text-gray-500 mt-1">
              Contains: {menuItem?.allergen.map((item) => item.allergen_name).join(', ')}
            </p>
          )} */}
          <div
            className="fixed bottom-0 right-0 left-0 bg-white z-10 shadow-inner"
            style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
          >
            {(!menuItem?.offer_p || offerSelectedMenuItems.length > 0) && (
              <div className="grid grid-cols-5 mt-8">
                <div className="flex justify-end col-span-2">
                  <button type="button" onClick={decrementCount} disabled={basket === 1}>
                    <MinusCircleIcon
                      className={`h-10 w-10 -mt-1 mr-1 inline ${basket === 1 ? 'text-gray-300' : 'text-primary'}`}
                      aria-hidden="true"
                      style={{ color: theme.general?.textColor || '' }}
                    />
                  </button>
                </div>
                <h1 className="text-center font-bold text-2xl col-span-1">{basket}</h1>
                <div className="flex justify-start col-span-2">
                  <button type="button" onClick={incrementCount}>
                    <PlusCircleIcon
                      className="h-10 w-10 -mt-1 mr-1 inline text-primary"
                      aria-hidden="true"
                      style={{ color: theme.general?.textColor || '' }}
                    />
                  </button>
                </div>
              </div>
            )}
            <div className="flex justify-center my-4">
              {menuItem?.offer_p ? (
                offerSelectedMenuItems.length === 0 ? (
                  <button
                    type="button"
                    onClick={confirmOffer}
                    className="bg-colorPrimary text-textPrimary w-80 py-2 rounded-full text-2xl font-semibold disabled:opacity-50"
                    disabled={requiredOfferSelected}
                    style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                  >
                    Confirm Offer
                  </button>
                ) : (
                  <button
                    type="button"
                    // onClick={() => updateBrandAndAddToBasket(addOfferToBasket)}
                    className="bg-colorPrimary text-textPrimary w-80 py-2 rounded-full text-2xl font-semibold disabled:opacity-50"
                    disabled={requiredOfferModifierSelected}
                    style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                  >
                    Add offer to Basket
                  </button>
                )
              ) : (
                <button
                  type="button"
                  onClick={() => updateBrandAndAddToBasket(onConfirmModifiers)}
                  className="bg-colorPrimary text-textPrimary w-80 py-2 rounded-full text-2xl font-semibold disabled:opacity-50"
                  disabled={requiredModifierSelected}
                  style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4">
        {menuItem?.offer_p ? (
          offerSelectedMenuItems.length === 0 ? (
            <Offer
              offerMenuItem={offerMenuItem}
              setRequiredOfferSelected={setRequiredOfferSelected}
              setSelectedOfferMenuItemIds={setSelectedOfferMenuItemIds}
            />
          ) : (
            <Tabs>
              {offerSelectedMenuItems?.map((item, index) => (
                <Tabs.TabPane
                  tab={`${item.menu_item.menu_item_name_en} ${item.iteration}`}
                  key={`${item.menu_item.menu_item_id}-${index}`}
                  className="capitalize"
                >
                  {item.menu_item.menu_item_external_description_en}
                  {/* <Modifiers
                    modifierSections={item.modifierSections || []}
                    setModifierSections={undefined}
                    setOfferSelectedMenuItems={setOfferSelectedMenuItems}
                    offerMenuItemIndex={index}
                  /> */}
                </Tabs.TabPane>
              ))}
            </Tabs>
          )
        ) : menuItem ? (
          <Modifiers
            item={menuItem}
            modifierSections={modifierSections}
            setModifierSections={setModifierSections}
            setOfferSelectedMenuItems={undefined}
            offerMenuItemIndex={undefined}
            setRequiredModifierSelected={setRequiredModifierSelected}
          />
        ) : null}
        <div className="px-4 flex flex-col gap-2 mt-2">
          <h2 className="font-semibold text-lg">Item Notes</h2>
          <div
            className="w-full px-2 py-1 rounded-xl shadow-md border"
            style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
          >
            <textarea
              value={itemNotes}
              rows={2}
              className="w-full border-0 focus:outline-none focus:ring-0"
              style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
              onChange={(e) => setItemNotes(e.target.value)}
              placeholder="Eg. No onions, extra cheese, etc."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
